// Fonts
@import url('https://fonts.googleapis.com/css?family=Poppins');
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/src/index.scss';
@import 'loader';

html, body, #app {
  width:100%;
  height: 100%;
}

#app {
  width: calc(100% - 320px);
  margin-left: 320px;
  transition: all 0.4s;
}

#sidebarMenu {
  min-width: 320px;
  width: 320px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.4s;
}

#sidebarMenu2 {
  min-width: 306px;
  margin-left: -321px;
  margin-top: 15px;
  width: 306px;
  height: 97%;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.4s;
  z-index: 2000;
  background-color: #000;
  color: #f4f5fa;
  border-radius: 15px;
 }

#sidebar {
  background-color:#000;
  border-radius: 15px;
  min-height: calc(97%);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

#app.active {
  width: 100%;
  margin: 0;
}

#sidebarMenu.active {
  margin-left: -320px;
}

#sidebarMenu2.active {
  margin-left: 7px;
  z-index: 2000;
}

@media (max-width: 768px) {
  #sidebarMenu {
    margin-left: -320px;
  }
  #sidebarMenu2 {
    margin-left: -320px;
  }
  #sidebarMenu.active {
    margin-left: 0;
    width: 100%;
    z-index: 1500;
  }
  #sidebarMenu2.b-icon.bi:hover {
    margin-left: 0;
    width: 100%;
    z-index: 1500;
  }
  #app {
    width: 100%;
    margin: 0;
  }
  #app.active {
    margin-left: 0px;
    width: 100%;
  }
}

.notifications {
  margin-top: 1em;
  margin-right: 1em;
}

.container {
  max-width: 100%
}

.container-fluid {
  max-width: 100%;
  overflow-x: hidden;
}

.chart-container {
  height: 800px;
}

.nav-container-settings {
  position: relative;
}

.progress {
  border-radius: 10px;
  border-color: #dee2e6;
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
}

.progress-bar {
  border-radius: 10px;
}


/*
 * Buttons
 */


.button-margin {
  font-weight: 400;
  margin-bottom: 1em;
  margin-right: 2em;
  border-radius: 12px;
  padding-left: 40px;
  padding-right: 40px;
  height: 50px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn .b-icon.bi {
  margin-right: 10px;
}

.b-icon.bi:hover {
  color: #000120;
}

/*
 * Card-Design
 */

.card {
  border-color: transparent;
  border-radius: 0.8rem;
}

.card-header {
  background-color: transparent;
  border-bottom-color: #000120;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  font-size: .8rem;
  text-transform: uppercase;
  color: #6c757d;
}

.info-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-family: times; 
  height: 1.3rem; 
  width: 1.3rem;
  font-size: 1.2rem;
  line-height: 1;
  padding: 0px;
}

.terms-text {
  font-size: 0.8em;
}

.terms-of-use {
  ol {
    counter-reset: item;
  }

  ol > li {
    counter-increment: item;
    text-transform: uppercase;
    font-weight: bolder;
  }

  ol ol > li {
    display: block;
    text-transform: none;
    font-weight: normal;
  }

  ol ol > li:before {
    content: counters(item, ".") ". ";
    margin-left: -28px;
  }
}

.img-fluid {
  width: 100%;
}

.preview-image {
  max-width: 150px;
  max-height: 150px;
}

body {
  background-color: #f4f5fa;
}

.custom-file-label {
  overflow: hidden;
}

.input-min-7rem {
    min-width: 7rem;
}

.input-nowrap {
  $enable-grid-classes: nowrap;
}


/*
 * Footer
 */

.application-footer {
  margin-top: auto;
  margin-bottom: 10px;
  font-size: 0.8em;
  text-align: center;
  color: #f4f5fa;
}

/*
 * Nav-Tabs, Nav-Pills
 */

 .nav-tabs {
  flex-wrap: nowrap;
  white-space: nowrap;
  max-width: 100%;
  overflow-y: hidden;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  scrollbar-width: thin;
  margin-bottom: 20px;
}

.nav-tabs .nav-link.active {
  color: #000120;
  background-color: #E8EAF4;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  border-bottom-color: #000120;
  border-bottom-width: 5px;
}

.nav-tabs .nav-link:hover {
  color: #000120;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  border-bottom-color: #000120;
  border-bottom-width: 5px;
}

.nav-tabs {
  flex-wrap: nowrap;
  white-space: nowrap;
  max-width: 100%;
  overflow-y: hidden;
}

.nav-pills .nav-link:hover {
  background-color: #E8EAF4;

}
.nav-pills .nav-link.active:hover {
  background-color: #000120;
}

.nav-pills .b-icon.bi {
  margin-right: 10px;
}


/*
 * Tables
 */

 .table-scrollable {
  overflow-x: auto;
  width: 100%
}

.table.b-table {
  font-size: 0.95em;
  width: 100%;
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #f4f5fa;
}

.table-hover tbody tr:hover {
  background-color: #E8EAF4;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  /* rtl:raw:
  right: 0;
  */
  bottom: 0;
  /* rtl:remove */
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  min-width: 250px;
  height: calc(100vh);
  overflow-y: auto;
  background-color: #f4f5fa;
}

.bg-light {
  background-color: #f4f5fa;
}

.scrollbar {
  scrollbar-width: none;
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 30px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

.category {
  font-size: .85rem;
  text-transform: uppercase;
}

.sidebar .nav-link {
  font-weight: 400;
  color: #fff;
  border-color: transparent;
  border-width: 2px;
  border-style: solid;
  margin-left: 10px;
  border-radius: 15px;
}

.sidebar .nav-link .nav-link-icon {
  margin-right: 5px;
  color: #fff;
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.sidebar .nav-link:hover {
  color: #fff;
  border-radius: 11px;
  border-color: white; /* Änderung für den weißen Rahmen */
  border-style: solid;
  border-width: 2px;
  margin-left: 10px;
  margin-right: 10px;

}
 
.sidebar .nav-link:hover .nav-link-icon {
  color: #fff; 
}

.sidebar .router-link-active,
.sidebar .router-link-active:hover {
  color: #000;
  background-color: #f4f5fa;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 11px;
}

.sidebar .router-link-active .nav-link-icon,
.sidebar .router-link-active:hover .nav-link-icon {
  color: #000;
}

.dropdown-item {
  color: #000;
  background-color: transparent;
  max-width: 95%;
  border-radius: 6px;
}

.dropdown-item:hover {
  color: #000;
  background-color: #E8EAF4;
}

/*
 * Navbar
 */

.navbar {
  background-color: transparent;
  opacity: 100%;
}

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: transparent;
  box-shadow: none;
}

.navbar .navbar-toggler {
  top: 1rem;
  right: 1rem;
}

.navbar .navbar-toggler:hover {
  background-color: transparent;
}

.vl {
  border-left: 1px solid #fff;
  height:  calc(100vh);
}

.bg-dark {
  background-color: #202644;
  opacity: 85%;
}